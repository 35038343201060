import { Suspense } from "react";

const SuspenseWrapper = ({ dependencies, children, fallback }) => {
	const isLoading = dependencies.some((dep) => !dep);

	if (isLoading) {
		return fallback;
	}

	return <Suspense fallback={fallback}>{children}</Suspense>;
};

export default SuspenseWrapper;
