import React from "react";
import MainTitle from "../MainTitle/MainTitle";

import SouqAtlbhaSwiper from "../SwiperBox/SouqAtlbhaProductsSwiper/SouqAtlbhaSwiper";
import ShowAllProductsButton from "../ShowAllProductsButton";

const HomePageSection = ({
	sectionTitle,
	sliderData,
	isLoading,
	navigateTo,
}) => {
	return (
		<div className='stores-info p-main'>
			<div className='container'>
				<MainTitle text={sectionTitle} />

				<SouqAtlbhaSwiper productSwiperData={sliderData} />

				{sliderData?.length >= 5 && (
					<ShowAllProductsButton
						isLoading={isLoading}
						navigateTo={navigateTo}
					/>
				)}
			</div>
		</div>
	);
};

export default HomePageSection;
