import React from "react";

// Third party
import { useNavigate } from "react-router-dom";

// Components
import {
	ProductSwiper,
	ReviewSwiper,
	PartnerSwiper,
	WhatWeOffer,
	FeaturedStores,
	SubscribePackages,
	HeroBanners,
	WhyAtlbha,
	HomePageSection,
	ShowAllProductsButton,
} from "../../index";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// css styles
import "./HomeBox.css";

// Icons
import { MainTitle } from "../../index";

const HomeBox = ({
	homePageData,
	homeLoadingData,
	isFetching,
	setUseDisplayStores,
}) => {
	const navigate = useNavigate();

	// ------------------------------------------------------------------

	// URL generation utility
	const generatePostUrl = (id, title) => {
		const formattedTitle = title
			.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
			.toLowerCase();
		return `/all-products/${id}/${encodeURIComponent(formattedTitle)}`;
	};

	return (
		<>
			{/* ============= Hero Banners ===================== */}
			<HeroBanners homePageData={homePageData} navigate={navigate} />

			{/* ============= What We Offer ===================== */}
			<WhatWeOffer />

			{/* ============= Featured Products ===================== */}
			{homePageData?.content_section1?.length > 0 ? (
				<div className='outstanding-products p-main  '>
					<div className='container'>
						<MainTitle text={homePageData?.section1.name} />
						<div className='all mb-4'>
							<ProductSwiper
								productSwiperData={homePageData?.content_section1}
							/>
						</div>

						{homePageData?.content_section1?.length >= 6 && (
							<ShowAllProductsButton
								isLoading={homeLoadingData}
								navigateTo='/all-products?special'
							/>
						)}
					</div>
				</div>
			) : null}

			{/* ============ Wholesale market  ===================== */}
			{homePageData?.content_section2?.length > 0 ? (
				<div className='outstanding-products home p-main '>
					<HomePageSection
						isLoading={homeLoadingData}
						sectionTitle={homePageData?.section2.name}
						sliderData={homePageData?.content_section2}
						navigateTo='/all-products'
					/>
				</div>
			) : null}

			{/* ============= section3 ===================== */}
			{homePageData?.content_section3?.length > 0 ? (
				<HomePageSection
					isLoading={homeLoadingData}
					sectionTitle={homePageData?.section3.name}
					sliderData={homePageData?.content_section3}
					navigateTo={generatePostUrl(
						homePageData?.section3.category_id,
						homePageData?.section3.name
					)}
				/>
			) : null}

			{/* ============= Featured Stores ===================== */}
			{homePageData?.content_section4?.length > 0 ? (
				<FeaturedStores
					isFetching={isFetching}
					homePageData={homePageData}
					setUseDisplayStores={setUseDisplayStores}
				/>
			) : null}

			{/* ============= section5 ===================== */}
			{homePageData?.content_section5?.length > 0 ? (
				<HomePageSection
					isLoading={homeLoadingData}
					sectionTitle={homePageData?.section5.name}
					sliderData={homePageData?.content_section5}
					navigateTo={generatePostUrl(
						homePageData?.section5.category_id,
						homePageData?.section5.name
					)}
				/>
			) : null}

			{/* ============= section6 ===================== */}
			{homePageData?.content_section6?.length > 0 ? (
				<HomePageSection
					isLoading={homeLoadingData}
					sectionTitle={homePageData?.section6.name}
					sliderData={homePageData?.content_section6}
					navigateTo={generatePostUrl(
						homePageData?.section6.category_id,
						homePageData?.section6.name
					)}
				/>
			) : null}

			{/* ============= section7 ===================== */}
			{homePageData?.content_section7?.length > 0 ? (
				<HomePageSection
					isLoading={homeLoadingData}
					sectionTitle={homePageData?.section7.name}
					sliderData={homePageData?.content_section7}
					navigateTo={generatePostUrl(
						homePageData?.section7.category_id,
						homePageData?.section7.name
					)}
				/>
			) : null}

			{/* ============= Why Atlbha ===================== */}
			<WhyAtlbha />

			{/* ============= Our Packages ===================== */}
			<div className='our-package p-main'>
				<div className='container'>
					<MainTitle text={"باقات اطلبها"} />
					<SubscribePackages />
				</div>
			</div>

			{/* ============= Our Reviews ===================== */}
			{homePageData?.comment?.length > 0 ? (
				<>
					<MainTitle text={"قالوا عنا"} />
					<ReviewSwiper DataReviewSwiper={homePageData?.comment} />
				</>
			) : null}

			{/* ============= Our Partners ===================== */}
			{homePageData?.partners?.length > 0 ? (
				<>
					<MainTitle text={"شركاء النجاح"} />
					<PartnerSwiper PartnerDataSwiper={homePageData?.partners} />
				</>
			) : null}
		</>
	);
};

export default HomeBox;
